import japaneseMessages from '@bicstone/ra-language-japanese';
import { TranslationMessages } from 'ra-core';
const bookTranslationField = {
  id: 'id',
  isbn: 'ISBN',
  latestIsbn: '最新版ISBN',
  operator: '作業者',
  confirmerId: 'チェック者',
  workStatus: '作業状況',
  publisherInfo: {
    id: '出版社ID',
    name: '出版社名',
  },
  pdfDataUrl: 'GoogleDriveのURL',
  allowPDFPublish: 'PDF掲載',
  allowWebPublish: 'Web掲載',
  allowDownloadAndPrint: '印刷・ダウンロード',
  allowCopyContent: '内容のコピー',
  allowAddLink: 'リンク付与可否',
  tosNotAllowedPage: '掲載不可ページ',
  isExistCoverImage: '表紙画像の有無',
  bookTitle: '表紙上の全タイトル',
  coverAuthor: '表紙上の執筆者',
  colophonPageNumber: '奥付のページ番号',
  colophonAuthor: '著者・編集者・編者・編著者・監修者',
  bookVersion: '版',
  startPublicationDate: '発行年月日',
  fullTitle: '全タイトル',
  printerInfo: {
    id: '印刷所ID',
    name: '印刷所名',
  },
  lsStartPublicationDate: 'LS 掲載開始日',
  lsEndPublicationDate: 'LS 掲載終了日',
  printing: '刷',
  bookType: '種別',
  clientCode: '先方指定コード',
  purchaseType: '販売形式',
  reservableFrom: '予約開始日',
};
const translation: TranslationMessages = {
  ...japaneseMessages,
  resources: {
    organizations: {
      name: '組織',
      fields: {
        name: '名前',
        subdomain: 'サブドメイン',
        oid: 'OID',
        pricingType: '料金計算方式',
        trialFrom: 'トライアル開始日',
        trialUntil: 'トライアル終了日',
        paidFrom: '本利用開始日',
        paidUntil: '本利用終了日',
        packId: 'パックID',
        validFrom: '利用開始日',
        validUntil: '利用終了日',
        loginType: 'ログイン種別',
        organizationType: '組織種別',
        isLegalOn: 'LegalOn 販売組織',
        packs: '出版社パック',
        'packs.packId': 'パックID',
        'packs.validFrom': '利用開始日',
        'packs.validUntil': '利用終了日',
        jobTitles: '肩書一覧',
        'jobTitles.name': '肩書名',
        'jobTitles.billable': '課金対象かどうか',
        sellerId: '販売経路',
      },
    },
    adminUsers: {
      name: '管理画面ユーザー',
      fields: {
        id: 'ID',
        firebaseId: 'firebaseId',
        name: '名前',
        createdAt: '作成日',
      },
    },
    users: {
      name: 'ユーザー',
      fields: {
        id: 'ID',
        firebaseId: 'firebaseId',
        name: '名前',
        email: 'メールアドレス',
        objectId: 'オブジェクトID',
        validFrom: '開始',
        validUntil: '終了',
        createdAt: '作成日',
      },
      resetPassword: {
        title: 'ユーザーのパスワードリセット',
      },
    },
    permissions: {
      name: 'パーミッション',
      fields: {
        id: 'ID',
        name: '名前',
        description: '説明',
        permissionIds: 'パーミッション',
      },
    },
    trialUserRequests: {
      name: 'トライアル',
      fields: {
        id: 'ID',
        trialUrl: 'トライアル申請URL',
        subdomain: 'サブドメイン',
        requestStatus: 'ステータス',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        trialUserRequestDetails: 'トライアル申請',
        name: '名前',
        email: 'メールアドレス',
        objectId: 'オブジェクトID',
        validFrom: '課金開始日',
      },
      action: {
        create: 'トライアル申請URL発行',
      },
    },
    requests: {
      name: '申請',
      fields: {
        id: 'ID',
        requestStatus: 'ステータス',
        requestType: '申請種別',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        addUserRequestDetails: '追加申請',
        editUserRequestDetails: '編集申請',
        deleteUserRequestDetails: '削除申請',
        enrollmentRequestDetail: '本利用申請',
        userId: 'ユーザーID',
        requestUser: {
          name: '申請者名',
        },
        name: '名前',
        jobTitleId: '肩書',
        newJobTitleName: '新しい肩書',
        billable: '課金対象',
        email: 'メールアドレス',
        objectId: 'オブジェクトID',
        preferredDate: '希望日',
        jobTitle: {
          id: 'ID',
          name: '肩書名',
          billable: '課金対象かどうか',
          createdAt: '作成日時',
          updatedAt: '更新日時',
        },
        organization: {
          name: '組織名',
          pricingType: 'プライシングタイプ',
        },
        oid: '組織ID',
        userCountTimeline: '有効アカウント数',
      },
    },
    tasks: {
      name: '開発者用機能',
      fields: {
        id: 'タスク名',
      },
      ids: {
        recreateAllOrgViews: '組織横断ビュー再構築',
      },
    },
    books: {
      name: '書誌情報',
      fields: bookTranslationField,
    },
    reviewedBooks: {
      name: 'レビュー済み書誌情報',
      fields: bookTranslationField,
    },
    publishers: {
      name: '出版社',
      fields: {
        id: 'id',
        name: '出版社名',
      },
    },
    printers: {
      name: '印刷所',
      fields: {
        id: 'id',
        name: '印刷所名',
      },
    },
    pdf: {
      name: 'PDF',
      action: {
        create: 'PDFをアップロード',
      },
      fields: {
        isbn: 'ISBN',
      },
    },
  },
  enum: {
    pricingType: {
      lawFirm: '旧プラン（法律事務所）',
      companyLegalDepartment: '旧プラン（企業法務部）',
      lawFirmSmall: '法律事務所30名以下',
      lawFirmMedium: '法律事務所31名以上100名以下',
      lawFirmLarge: '法律事務所101名以上',
      lawFirmBig5: '法律事務所五大',
      companyLegalDepartmentNormal: '企業法務部通常プラン',
      lawFirmCustomized: '法律事務所カスタム（廃止済み）',
      companyLegalDepartmentOtameshi: '企業法務部お試しプラン',
      legalOn: 'LegalOn 販売プラン',
    },
    trialRequestStatus: {
      beforeApplication: '申請前',
      applying: '申請中',
      processing: '処理中',
      treated: '処理済',
      rejected: '棄却',
    },
    requestStatus: {
      applying: '受理待ち',
      treated: '処理済み',
      rejected: '棄却',
    },
    requestType: {
      addUserRequest: 'ユーザー追加申請',
      editUserRequest: 'ユーザー修正申請',
      deleteUserRequest: 'ユーザー削除申請',
      enrollmentRequest: '本利用申請',
    },
    loginType: {
      password: 'パスワード認証',
      microsoft: 'Microsoft認証',
      google: 'Google認証',
      emailLink: 'メールリンク認証',
    },
    organizationTypeId: {
      '1': '企業法務部',
      '2': '法律事務所',
      '3': '士業事務所（法律事務所は除く）',
      '999': 'その他',
    },
    sellerId: {
      1: '直販',
      2: 'LegalOn Technologies',
    },
    allowPDFPublish: {
      false: 'なし',
      true: 'あり',
    },
    allowWebPublish: {
      false: 'なし',
      true: 'あり',
    },
    allowDownloadAndPrint: {
      false: 'なし',
      true: 'あり',
    },
    allowCopyContent: {
      false: 'なし',
      true: 'あり',
    },
    allowAddLink: {
      false: 'なし',
      true: 'あり',
    },
    isExistCoverImage: {
      false: 'なし',
      true: 'あり',
    },
    workStatus: {
      pdfRegistered: 'PDF登録済み',
      bibliographicInfoEntered: '書誌情報入力済み',
      reviewed: 'レビュー済み',
      remand: '差し戻し',
    },
    bookType: {
      book: '書籍',
      magazine: '雑誌',
    },
    purchaseType: {
      default: 'デフォルト',
      purchasable: 'ストア販売あり',
    },
  },
  error: {
    trialUntilShouldBeAfterTrialFrom: 'トライアル終了日はトライアル開始日より後の日時を指定してください',
    paidUntilShouldBeAfterPaidFrom: '本利用終了日は本利用開始日より後の日時を指定してください',
    packValidUntilShouldBeAfterPackValidFrom: 'パック利用終了日はパック利用開始日より後の日時を指定してください',
    invalidOrganizationStateWithinTrialWithinPaid: 'トライアルと本利用の期間を重複させることはできません',
    invalidOrganizationStateBeforeTrialWithinPaid:
      '本利用期間中にトライアル予定を設定すること（またはその逆）はできません',
    invalidOrganizationStateBeforeTrialBeforePaid: '本利用開始予定日はトライアルが開始した後に記入してください',
    invalidOrganizationStateBeforeTrialAfterPaid:
      '本利用終了後にトライアル予定を設定することはできません。開発者にご連絡ください',
    invalidOrganizationStateWithinTrialAfterPaid:
      '本利用終了後にトライアル中にすることはできません。開発者にご連絡ください',
    duplicatedSubdomain: 'すでに同じサブドメインが存在します',
    notFound: 'データが見つかりませんでした',
    conflict: 'データ更新前の競合を検知しました。ページ更新後、再度実行してください。',
    hasOlderApplyingRequest: 'この申請よりも過去の未処理の申請があるため実行できません。その申請の対応をお願いします。',
    unknown: 'エラーが発生しました。開発者にご連絡ください。',
    emailAlreadyExists: 'このメールアドレスはすでに存在します',
  },
  validation: {
    openapi: {
      serdes: '入力形式に誤りがあります',
    },
  },
  notification: {
    simpleUpdated: '更新しました',
    completed: '完了しました',
  },
  label: {
    invoke: '実行する',
    reset: 'リセット',
  },
  messages: {
    confirm_invoke: '%{name}を実行します。よろしいですか？',
    cannotTreatBecauseOfOrganizationState:
      '組織の契約期間によりこの申請は承認できません。詳しくは開発者に確認してください',
    createUser: '作成したユーザーのパスワードは %{password} です。一度しか表示しないためここでメモしてください。',
    resetUserPassword: '新しいユーザーのパスワードは %{password} です。一度しか表示しないためここでメモしてください。',
  },
};

export default translation;
