import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  ArrayField,
  Datagrid,
  ChipField,
  SingleFieldList,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';

const OrganizationTitle = () => {
  const record = useRecordContext();
  // ロード中は空
  if (!record) return null;
  return <span>{record.name}</span>;
};

export const OrganizationShow: React.FC = () => (
  <Show title={<OrganizationTitle />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="id" />
      <TextField source="subdomain" />
      <TextField source="oid" />
      <WrapperField label="トライアル">
        <DatetimeField source="trialFrom" />
        〜
        <DatetimeField source="trialUntil" />
      </WrapperField>
      <WrapperField label="本利用">
        <DatetimeField source="paidFrom" />
        〜
        <DatetimeField source="paidUntil" />
      </WrapperField>
      <ReferenceArrayField reference="permissions" source="permissionIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TranslatedField source="sellerId" prefix="enum.sellerId" />
      <TranslatedField source="pricingType" prefix="enum.pricingType" />
      <TranslatedField source="loginType" prefix="enum.loginType" />
      <ArrayField source="packs">
        <Datagrid bulkActionButtons={false}>
          <TextField source="packId" />
          <DatetimeField source="validFrom" />
          <DatetimeField source="validUntil" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
