import * as React from 'react';
import {
  ArrayInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import HiddenInput from 'src/components/HiddenInput';
import { pricingTypes, sellerIds } from './field';

const OrganizationEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const OrganizationEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm shouldUnregister={true} toolbar={<OrganizationEditToolbar />}>
        <TextField source="oid" />

        <FormGlobalError />

        <HiddenInput source="updatedAtMillis" />
        <TextInput source="name" />
        <CustomDateTimeInput source="trialFrom" />
        <CustomDateTimeInput source="trialUntil" />
        <CustomDateTimeInput source="paidFrom" />
        <CustomDateTimeInput source="paidUntil" />
        <ReferenceArrayInput
          reference="permissions"
          source="permissionIds"
          sort={{ field: 'id', order: 'ASC' }}
          perPage={1000}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput validate={required()} source="sellerId" choices={sellerIds} />
        <SelectInput source="pricingType" choices={pricingTypes} defaultValue={null} />

        <ArrayInput source="packs">
          <SimpleFormIterator disableRemove disableReordering inline>
            <NumberInput source="packId" />
            <CustomDateTimeInput source="validFrom" />
            <CustomDateTimeInput source="validUntil" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
