import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  EditButton,
  CreateButton,
  Pagination,
  WithRecord,
  TextInput,
} from 'react-admin';
import DomainIcon from '@mui/icons-material/Domain';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';
import LinkButton from '../../components/LinkButton';

export const OrganizationIcon = DomainIcon;

const ListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const orgFilters = [<TextInput label="組織名orサブドメインで検索" source="q" name="q" alwaysOn />];

export const OrganizationList: React.FC = () => {
  return (
    <List
      filters={orgFilters}
      actions={<ListActions />}
      perPage={10}
      pagination={<Pagination rowsPerPageOptions={[10, 100, 1000]} />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField source="subdomain" />
        <TranslatedField source="sellerId" prefix="enum.sellerId" />
        <TranslatedField source="pricingType" prefix="enum.pricingType" />
        <TranslatedField source="loginType" prefix="enum.loginType" />
        <DatetimeField source="trialFrom" />
        <DatetimeField source="trialUntil" sortable={false} />
        <DatetimeField source="paidFrom" />
        <DatetimeField source="paidUntil" sortable={false} />
        <EditButton />
        <WithRecord
          render={(record) => (
            <LinkButton
              to={{
                pathname: '/requests',
                search: `filter=${JSON.stringify({ organization: record.id })}`,
              }}
              label="申請一覧"
            />
          )}
        />
        <WithRecord
          render={(record) => (
            <LinkButton
              to={{
                pathname: '/users',
                search: `filter=${JSON.stringify({ oid: record.oid })}`,
              }}
              label="ユーザー"
            />
          )}
        />
      </Datagrid>
    </List>
  );
};
export * from './create';
export * from './update';
