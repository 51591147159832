import * as React from 'react';
import { Layout, LayoutProps, Menu, ResourceMenuItem, useResourceDefinitions } from 'react-admin';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const SideMenu: React.FC = () => {
  const resources = useResourceDefinitions();
  return (
    <Menu>
      {/* TODO 下記コードは本来 <Menu.ResourceItems /> を使うべきだが TS2551 エラーにより利用できなかったため、ResourceItems の実装をそのまま記述しています */}
      {Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => (
          <ResourceMenuItem key={name} name={name} />
        ))}
      <Menu.Item to="/pdf" primaryText="PDFアップロード" leftIcon={<AdminPanelSettingsIcon />} />
    </Menu>
  );
};

export const SideMenuLayout = ({ children, ...props }: LayoutProps) => (
  <Layout {...props} menu={SideMenu}>
    {children}
  </Layout>
);
